<template>
  <div class="containerx">
    <div class="main-box">
      <div class="left-box">
        <div class="left-top-box">
          <span>层级</span>
        </div>
        <div class="left-down-box">
          <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick"
            :highlight-current="true"></el-tree>
        </div>
      </div>
      <div class="right-box">
        <div class="right-top-box">
          <span class="box-title">设备列表</span>
        </div>
        <div class="right-down-box" element-loading-text="拼命加载中"
          element-loading-background="rgba(255, 255, 255, 0.3)" customClass="">
          <span class="notification" v-if="equipmentList.length < 1">
            <span>暂无数据</span>
          </span>
          <div class="item-box">
            <div class="table-box">
              <el-table border :stripe="true" v-loading="loadingEquipment" ref="multipleTable" :data="equipmentList"
                tooltip-effect="dark" style="width: 100%" >
                <el-table-column label="序号" type="index" width="50">
                </el-table-column>
                <el-table-column prop="name" label="设备名称" width="120">
                </el-table-column>
                <el-table-column prop="serialNumber" label="设备编号" width="220" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="monitorSerialNumber" label="物联编号" width="220" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="state" label="设备状态" width="120">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.state === 0 ? 'success' : scope.row.state === 1 ? 'warning' : 'danger'"
                      disable-transitions>{{ scope.row.state === 0 ? '在线' : scope.row.state === 1 ? '离线' : '异常'
                      }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button type="primary" @click="toSet(scope.row)" plain>告警设置</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div style="height: 3rem;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="queryForm.page" :page-size="queryForm.size" layout="prev, pager, next, jumper"
              :total="pageTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="告警设置" :visible.sync="setWarningVisible" :show-close="true">
    <setWarning  :equipmentId="selectEquipmentId"></setWarning>
    <div slot="footer" class="dialog-footer" >
      <el-button @click="setWarningVisible = false">取 消</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import setWarning from '../../components/setWarning.vue'
export default {
  name: 'warningSet',
  components: {
    setWarning
  },
  data() {
    return {
      setWarningVisible: false,
      loadingEquipment: false,
      treeData: [],
      defaultProps: {
        children: 'lowerLevelList',
        label: 'levelName'
      },
      equipmentList: [
      ],
      selectEquipmentId: null,
      queryForm: {
        page: 0,
        size: 6,
        type: 0,
        levelId: null,
        projectId: 1,
      },
      pageTotal: 0,
      countValue: {
        online: 0,
        offline: 0,
        err: 0
      }
    }
  },
  mounted() {
    this.getTreeData()
    this.countStat()
  },
  methods: {
    getTreeData() {
      // 获取项目列表
      this.$newGet('/level/getByProjectId'
      ).then(res => {

        if (res.success) {
          this.treeData = res.data
        }
      })
    },
    toSet(item) {
        console.log(item)
        this.selectEquipmentId = item.id
				this.setWarningVisible = true
			},
    countStat() {
      this.$newPost('/equipment/count/stat', this.queryForm).then(res => {
        res.data.forEach((ele, index) => {
          if (1 == ele.state) {
            this.countValue.offline = ele.number
          } else if (0 == ele.state) {
            this.countValue.online = ele.number
          } else if (2 == ele.state) {
            this.countValue.err = ele.number
          }
        })

      }).catch(err => {
      })
    },
    handleSizeChange(val) {
      this.loadingEquipment = true
      this.queryForm.pageSize = val
      this.queryPage()
    },
    handleCurrentChange(val) {
      this.loadingEquipment = true
      this.queryForm.page = val
      this.queryPage()
    },
    handleNodeClick(data) {
      this.loadingEquipment = true

      this.queryForm.levelId = data.id
      this.queryPage()
    }
    , queryPage() {
      this.$newPost('/equipment/page', this.queryForm).then(res => {
        this.loadingEquipment = false
        this.equipmentList = res.data.records
        this.pageTotal = res.data.total

      }).catch(err => {
        this.loadingEquipment = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.bg {
  background-color: rgba(43, 201, 222, 0.6);
}

.bg1 {
  background-color: rgba(162, 162, 162, 0.2);
}

.bg2 {
  background: rgba(14, 242, 242, 0.3);
}

.containerx {
  overflow: auto;
  background-color: #fcfcfc;
  width: 100%;
  height: 100%;

  .main-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .left-box {
      display: flex;
      flex-direction: column;
      width: 20%;
      height: 100%;
      border-right: 1px solid #24708dcc;

      .left-top-box {
        padding-left: 20px;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        width: 100%;
        height: 5rem;
        border-bottom: 1px solid #24708dcc;
        color: #202020;
      }

      .left-down-box {
        width: 100%;
        height: 100%;
      }
    }

    .right-box {
      display: flex;
      width: 80%;
      height: 100%;
      flex-direction: column;

      .right-top-box {
        width: 100%;
        height: 5rem;
        border-bottom: 1px solid #24708dcc;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .my-icon {
          width: 10px;
          height: 10px;
          margin-right: 5px;
          border-radius: 5px;
          border: 1px solid #202020;
        }

        .online {
          background-color: #67C23A;
        }

        .offline {
          background-color: #E6A23C;
        }

        .err {
          background-color: #F56C6C;
        }

        .text-lalel {
          margin-right: 20px;

          .value {
            color: #8a8a8a;
          }
        }

        .box-title {
          margin-left: 20px;
          font-size: 1.3rem;
          display: flex;
          color: #202020;
        }

        .total-count-box {
          margin-right: 20px;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
        }
      }

      .right-down-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;
        justify-content: center;

        .notification {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          color: #a9a9a9;
          font-size: 1.4rem;
        }

        .item-box {

          width: 100%;
          min-height: 45rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .table-box {
            margin-top: 20px;
            width: 99%;
            min-height: 45rem;
          }


        }
      }
    }

  }

}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 2rem;
  //margin-top: 2rem;
  height: 5rem;
  line-height: 5rem;
}

::v-deep .el-tree-node__label {
  font-size: 1rem;
}

::v-deep .el-tree-node__expand-icon {
  font-size: 1rem;
}

::v-deep .el-tree {
  margin-top: 0.5rem;
}

::v-deep .el-tree-node {
  margin-left: 0.5rem;
}


::v-deep .el-loading-spinner {

  .circular {
    width: 15rem;
    height: 5rem;
  }

  .path {
    stroke: #24b2eacc;
  }

  .el-loading-text {
    color: #24b2eacc;
    font-size: 1.2rem;
  }
}
</style>
